<template>
  <div class="app-Certificate">
    <div class="Certificate-wrapper">
      <el-row>
        <el-col :span="24">
          <div class="grid-content bg-purple-dark" style="text-align: left">
            <h1 style="font-size: 30px">{{ this.$t('certificate') }}</h1>
            <div class="websiteorder">
                <div>
                  <h1 style="text-align: center">Доставка</h1>
                  <h1>Самовывоз</h1>
                  <p>Вы можете самостоятельно забрать свой заказ в офисе компании бесплатно:</p>
                  <p>1. Россия, г.Москва, ул. Фридриха Энгельса, дом 20, стр. 2, этаж 2, пом.1, тел. +7(495)901 02 58</p>
                  <p>2. Монголия, г.Улан-Батор, 26-й квартал, Баянзурхский район, проспект Столичный, POYAL Plaz, дом 365,
                    этаж 4, офис 405 (напротив Национального парка), тел. 75076888, 95151983, 99128353</p>
                  <p>3. Казахстан, г.Алматы, ул. Сейфуллина 498, Бизнес-центр 'Алтын заман', этаж 4, каб. 402, тел. +7-727-272-13-79, +7-778-351-60-88</p>
                  <p>4. Таджикистан, г.Душанбе, ул. Бохтар 1/37, этаж 10, кабинет 1003, тел. +939120021</p>
                  <h1>Доставка в Сервисный центр</h1>
                  <p>В разных городах России и стран СНГ работают официальные представительства нашей
                    компании -  Сервисные центры. Вы также можете получить свой заказ в любом удобном для Вас Сервисном центре:</p>
                  <p style="text-decoration:underline">Россия</p>
                  <p><span style="font-size: 12px">⬤</span> Чувашская Республика, г.Чебоксары, ул. Карла Маркса, дом 52к2, тел. +79033797476</p>
                  <p><span style="font-size: 12px">⬤</span> Крым, г.Севастополь, Античный пр-т, дом 12, тел. +79780781398</p>
                  <p><span style="font-size: 12px">⬤</span> Крым, г. Керчь, ул. Ленина, дом 10, тел. +79787057498</p>
                  <p><span style="font-size: 12px">⬤</span> Республика Бурятия, г.Улан-Удэ, ул. Толстого, дом 23, офис 211, тел. +79243977769</p>
                  <p><span style="font-size: 12px">⬤</span> Иркутская область, г.Иркутск, ул. Свердлова-7, этаж 2, тел. +79149300235</p>
                  <p><span style="font-size: 12px">⬤</span> Новосибирская область, г.Новосибирск, ул. Советская, дом 64, офис 710/8, тел. +79930115117</p>
                  <p><span style="font-size: 12px">⬤</span> Республика Саха / Якутия, г.Якутск, пр. Ленина, дом 53, тел. +79141000901</p>
                  <p><span style="font-size: 12px">⬤</span> Ростовская область, г. Волгодонск, пр-т Строителей, дом 7Б, офис 1,тел. +79895073214</p>
                  <p><span style="font-size: 12px">⬤</span> Иркутская область, г.Иркутск, ул.Красноармейская, д.8, офис 6, тел.7+9501002491</p>
                  <p><span style="font-size: 12px">⬤</span> Томская область, г.Томск, пер. 1905 года, дом 5А, стр. 3, тел. +79138105001</p>
                  <p><span style="font-size: 12px">⬤</span> Республика Башкортостан, г.Уфа, ул. Братьев Кадомцевых, дом  8А, офис 204, тел. +79178068986</p>
                  <p><span style="font-size: 12px">⬤</span> Чечня, г.Грозный, ул. Трошева, дом 73, тел.+79128381661</p>
                  <p>Казахстан</p>
                  <p><span style="font-size: 12px">⬤</span> г.Нур-Султан, ул. Алматы 2, офис 2. ЖК TORONTO, тел. +77017770306</p>
                  <p><span style="font-size: 12px">⬤</span> г.Актобе, ул. Уалиханова, 29А, тел.+77750866357</p>
                  <p>Tajikistan/Таджикистан</p>
                  <p><span style="font-size: 12px">⬤</span> г.Душанбе, ул.Бохтар, 37/1, тел. +939120021</p>
                  <p>Корея</p>
                  <p><span style="font-size: 12px">⬤</span> GANGWON - DO DONGHAE - SI BAEGOL – GIL 32-4, 105 dong 604 ho 25818, тел. 821068151968</p>
                  <p>Монголия</p>
                  <p><span style="font-size: 12px">⬤</span> Ulaanbaatar city, Bayanzurkh district, 26th district, No. 405, Building 365,
                    Capital Circle Avenue, office phone number 75076888, 95151983, 99128353</p>
                  <h1>Доставка другими транспортными компаниями:</h1>
                  <p>При оформлении заказа Вы можете выбрать доставку с помощью другой транспортной компании, предварительно указав ее название в комментариях к заказу.
                    Перед отправкой Вашего заказа наш менеджер свяжется с Вами для подтверждения заказа и деталей отправки.</p>
                  <p>Россия</p>
                  <p>- Почта России, СДЭК, Достависта, СЛТК и др.</p>
                  <p>Казахстан</p>
                  <p>- Казпочта, Индрайвер,  курьерская служба Avis(уточняйте у менеджеров компании).</p>
                  <p>Корея</p>
                  <p>- Почта Росии</p>
                  <p>В Монголии и Таджикистане необходимо уточнить информацию в местном филиале компании.</p>
                </div>
            </div>
          </div>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Certificate',
  data() {
    return {}
  },
  computed: {},
  watch: {},
  mounted() {},
  methods: {},
}
</script>

<style lang="scss">
.el-tabs__item.is-active {
  color: #f39f43 !important;
  font-size: 26px;
  font-weight: 600;
}

.el-tabs__item {
  color: #333;
  font-size: 22px;
}
.MsoNormal {
  margin: 10px 0px;
  text-align: left;
}
a {
  text-decoration: none;
  font-family: Solomon_Sans;
  font-weight: 300;
  color: #333333;
  font-size: 14px;
  line-height: 20px;
}
</style>
<style scoped lang="scss">
.websiteorder {
  width: 1000px;
  margin:auto;
  text-align: justify;
  h1{
    font-size: 2.5rem;
  }
  p {
    font-size: 1.5rem;
    font-weight:500;
    line-height: 2rem;
  }
  .ulOne {
    li {
      font-size: 1.1rem;
      font-weight: 600;
      line-height: 1.5rem;
    }
  }
  .ulFour {
    li {
      line-height: 1.8rem;
      span:first-child {
        display: inline-block;
        width: 180px;
        font-weight: 700;
        font-size: 1.2rem;
      }
    }
  }

}
</style>
